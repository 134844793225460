import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en';

/**
 * Convert an array to a compound sentance
 *
 * EX: [bla, bla, bla] => `bla, bla, and bla`
 *
 * @param {array} list
 */
const formatList = new Intl.ListFormat('en-US', {
  style: 'long',
  type: 'conjunction',
});
export const getCompoundSentenceList = list => formatList.format(list);

/**
 * Uses a tablelookup for desiphering how to use data to strings
 *
 * EX: const subscriptionfilters = {
 * frequencyInWeeks: {label: 'Frequency in Weeks', content: (n: number) => (n < 2 ? `Once a week` : `Every ${n} weeks`)},
 * deliveryNext: date => date,
 * };
 *
 * converts { frequencyInWeeks: 4} => [{frequencyInweeks: 'Once every 4 weeks'}]
 *
 * @param {object} obj (object with key value pairs)
 * * @param {object} filters (table lookup)
 */
export const getListItems = (obj, filters) => {
  const list = [];
  const filterKeys = Object.keys(filters);
  for (let key of filterKeys) {
    if (!!obj[key]) {
      const content = filters[key].content(obj[key]);
      const label = filters[key].label;
      list.push({ label, content });
    }
  }
  return list;
};
