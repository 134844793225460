import { useMemo } from 'react';
import { useSWRConfig } from 'swr';
import { useAuthenticatedSWR, useService, useSession } from '../api';
import { PET_URL } from './constants';
import { PetService } from './service';

export const usePets = ({ hasSubscription } = {}) => {
  const { mutate: globalMutate, cache } = useSWRConfig();
  const { service: sessionService } = useSession();
  const service = useService(PetService);
  const { data, error, mutate } = useAuthenticatedSWR(PET_URL);
  const createPet = async pet => {
    const response = await service.create(pet);
    mutate(
      {
        ...data,
        results: [...(data?.results ?? []), response.data],
      },
      false,
    );
    return response;
  };

  // TODO(James) put this filtering in a query param
  const pets = useMemo(
    () =>
      data?.results.filter(pet => {
        if (hasSubscription != null) {
          if (hasSubscription && !pet.subscription_id) {
            return false;
          }

          if (!hasSubscription && pet.subscription_id) {
            return false;
          }
        }

        return true;
      }),
    [data?.results, hasSubscription],
  );

  const updatePet = async (petId, pet) => {
    const response = await service.update(petId, pet);
    mutate({
      ...data,
      results: data?.results.map(pet =>
        pet.id === petId ? response.data : pet,
      ) ?? [response.data],
    });
    const keys = cache.keys();
    for (const key of keys) {
      if (key.includes('product')) globalMutate(key, null, true);
    }
    return response;
  };

  const deletePet = async petId => {
    const response = await service.delete(petId);
    mutate(
      {
        ...data,
        results: data?.results.filter(pet => pet.id !== petId) ?? [],
      },
      true,
    );
    return response;
  };

  const listPets = async () => {
    const response = await service.list();
    mutate(response.data, false);
    for (const pet of response.data.results) {
      if (pet.id) {
        globalMutate(`${PET_URL}${pet.id}/`, pet, false);
      }
    }
    return response;
  };

  return {
    pets: sessionService.user?.id ? pets : [],
    isLoading: !error && !data,
    error,
    refreshPets: mutate,
    createPet,
    updatePet,
    deletePet,
    listPets,
  };
};
