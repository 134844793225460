import { useAuthenticatedSWR } from '../api';
import { useOrder } from '../orders';
import { PET_URL } from './constants';

export const useLatestOrderForPetId = petId => {
  const { data, error: ordersError } = useAuthenticatedSWR(
    petId ? `${PET_URL}${petId}/orders/` : null,
  );
  // TODO(James) remove last optional chain once all pets have working orders.
  const { order, error: orderError, isLoading } = useOrder(data?.[0]?.id);

  const error = ordersError || orderError;

  return {
    order,
    error,
    isLoading,
  };
};
