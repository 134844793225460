import {
  differenceInMonths,
  differenceInYears,
  format,
  subYears,
  getYear,
  eachYearOfInterval,
  eachMonthOfInterval,
  eachDayOfInterval,
  parseISO,
  parse,
  endOfMonth,
} from 'date-fns';
import { getCompoundSentenceList } from '@format/list';
import {
  PRONOUN_TYPES,
  PERSPECTIVE_ONE,
  PERSPECTIVE_TWO,
  PERSPECTIVE_THREE,
} from './constants';

const UNKNOWN_BREED = 'Unknown Adorable Mix';
const ALLERGY_PREFIX = {
  none: 'has no allergies',
  one: 'is allergic to ',
  many: 'is allergic to ',
};
const ACTIVITY = {
  Low: 'more mellow',
  Moderate: 'more active',
  High: 'highly active',
};
export const LIMIT_YEAR = 25;

export const getBreed = breeds => {
  const names = breeds?.map(breed => breed.name) ?? [];
  if (!names.length) {
    return UNKNOWN_BREED;
  }
  if (names.length > 1) {
    return names.join('/');
  }
  return names[0];
};

export const getAllergies = (allergies, prefix = ALLERGY_PREFIX) => {
  prefix = { ...ALLERGY_PREFIX, ...prefix };
  const names = allergies?.map(allergy => allergy.name) ?? [];
  if (!names.length) {
    return prefix.none;
  }
  if (names.length > 1) {
    return prefix.many.concat(getCompoundSentenceList(names));
  }
  return prefix.one.concat(names[0]);
};

/**
 * @param {Date | string} birthdate
 * @returns
 */
export const getAge = birthdate => {
  // TODO(James) extract to `ensureDate` or similar?
  if (typeof birthdate === 'string') {
    birthdate = parseISO(birthdate);
  }
  const months = differenceInMonths(Date.now(), birthdate);
  const years = differenceInYears(Date.now(), birthdate);

  if (months < 12) {
    return `${months} months old`;
  }
  if (months === 12) {
    return `1 year old`;
  }
  if (months % 12 === 0) {
    return `${months / 12} years old`;
  }
  const yearLabel = years > 1 ? 'years' : 'year';
  return `${years} ${yearLabel} and ${months % 12} months old`;
};

/**
 * @param {Date | string} birthdate
 * @returns
 */
export const getAgeAdjective = birthdate => {
  // TODO(James) extract to `ensureDate` or similar?
  if (typeof birthdate === 'string') {
    birthdate = parseISO(birthdate);
  }
  const months = differenceInMonths(Date.now(), birthdate);
  const years = differenceInYears(Date.now(), birthdate);

  if (months < 12) {
    return `${months} month old`;
  }
  if (months === 12) {
    return `1 year old`;
  }
  if (months % 12 === 0) {
    return `${months / 12} year old`;
  }

  return `${years} year and ${months % 12} month old`;
};

export const getNeutered = (gender, neutered) => {
  const neuteredLabel = gender === 'Female' ? 'spayed' : 'neutered';
  return neutered ? `is ${neuteredLabel}` : `isn't ${neuteredLabel}`;
};

export const getActivity = activity => {
  return ACTIVITY[activity];
};

const throwGenderError = () => {
  throw new Error(
    'Value is not valid Gender. Must be Female, Male, or NoGender (default)',
  );
};

const throwPerspectiveError = () => {
  throw new Error(
    'Value is not valid perspective. Must be number between 1 & 3',
  );
};

const throwPronounTypeError = type => {
  throw new Error(`Type of value '${type}' is not valid`);
};

/**
 * Function returns pronoun based on type and perspective
 * @param {string} gender
 * @property {string} type - Subject, Object, Possessive Adjective, Possessive Pronouns, Reflexive
 * @property {number} perspective - First (1), Second (2), or third (3) person
 * @returns {string} Pronoun
 */

export const getPronoun = (
  gender,
  { type = null, perspective = null } = {},
) => {
  //First check if all three are missing, because thats too much for this to assume at once.
  if (!gender && !type && !perspective) throwPerspectiveError();
  //If theres at least one, we set the other two to these defaults.
  if (!gender) gender = 'NoGender';
  if (!type) type = PRONOUN_TYPES.POSSESSIVE_ADJECTIVE;
  if (!perspective) perspective = 1;

  if (!['Female', 'Male', 'NoGender'].includes(gender)) throwGenderError();

  if (perspective === 1) {
    return PERSPECTIVE_ONE[type] ?? throwPronounTypeError(type);
  }
  if (perspective === 2) {
    return PERSPECTIVE_TWO[type] ?? throwPronounTypeError(type);
  }
  if (perspective === 3) {
    return PERSPECTIVE_THREE[gender][type] ?? throwPronounTypeError(type);
  } else {
    throwPerspectiveError();
  }
};

const sortDesc = data =>
  data.sort(function (a, b) {
    return b.value - a.value;
  });

export const getYears = () => {
  const currentDate = new Date();
  const years = eachYearOfInterval({
    start: subYears(currentDate, LIMIT_YEAR),
    end: currentDate,
  }).map(item => ({
    value: getYear(item).toString(),
    label: getYear(item).toString(),
  }));
  return sortDesc(years);
};

export const getMonths = year => {
  if (year <= 0) {
    return [];
  }
  const currentYear = getYear(new Date());
  const startDate = parse(`${year}-1-1`, 'yyyy-MM-dd', new Date());
  const endDate =
    Number(currentYear) === Number(year)
      ? new Date()
      : parse(`${year}-12-1`, 'yyyy-MM-dd', new Date());
  return eachMonthOfInterval({
    start: startDate,
    end: endDate,
  }).map(item => ({ value: format(item, 'MM'), label: format(item, 'MMMM') }));
};

export const getDayList = (year, month, labelDay) => {
  if (month <= 0 || month > 12) {
    return [];
  }

  const startDate = parse(`${year}-${month}-1`, 'yyyy-MM-dd', new Date());
  const endDate = endOfMonth(startDate);
  const days = eachDayOfInterval({
    start: startDate,
    end: endDate,
  }).map(item => ({ value: format(item, 'dd'), label: format(item, 'dd') }));
  return [{ label: labelDay, value: '' }, ...days];
};
