export const PET_URL = `/api/v3/pets/`;
export const MIN_WEIGHT = 3;
export const MAX_WEIGHT = 180;
export const WEIGHT_ERROR = `Ollie is currently available only to pups between ${MIN_WEIGHT} and ${MAX_WEIGHT} pounds`;
export const WEIGHT_FORMAT_ERROR = `Please use a whole number for pup weight`;
export const EMPTY_PET = {
  breeds: [],
  allergies: [],
  name: '',
  id: null,
  birth_date: null,
};

export const PRONOUN_TYPES = {
  SUBJECT: 'Subject',
  OBJECT: 'Object',
  POSSESSIVE_ADJECTIVE: 'Possessive Adjective',
  POSSESSIVE_PRONOUNS: 'Possessive Pronouns',
  REFLEXIVE: 'Reflexive',
};

export const PERSPECTIVE_ONE = {
  [PRONOUN_TYPES.SUBJECT]: 'i',
  [PRONOUN_TYPES.OBJECT]: 'me',
  [PRONOUN_TYPES.POSSESSIVE_ADJECTIVE]: 'my',
  [PRONOUN_TYPES.POSSESSIVE_PRONOUNS]: 'mine',
  [PRONOUN_TYPES.REFLEXIVE]: 'myself',
};

export const PERSPECTIVE_TWO = {
  [PRONOUN_TYPES.SUBJECT]: 'you',
  [PRONOUN_TYPES.OBJECT]: 'you',
  [PRONOUN_TYPES.POSSESSIVE_ADJECTIVE]: 'your',
  [PRONOUN_TYPES.POSSESSIVE_PRONOUNS]: 'yours',
  [PRONOUN_TYPES.REFLEXIVE]: 'yourself',
};

export const PERSPECTIVE_THREE = {
  Female: {
    [PRONOUN_TYPES.SUBJECT]: 'she',
    [PRONOUN_TYPES.OBJECT]: 'her',
    [PRONOUN_TYPES.POSSESSIVE_ADJECTIVE]: 'her',
    [PRONOUN_TYPES.POSSESSIVE_PRONOUNS]: 'hers',
    [PRONOUN_TYPES.REFLEXIVE]: 'herself',
  },
  Male: {
    [PRONOUN_TYPES.SUBJECT]: 'he',
    [PRONOUN_TYPES.OBJECT]: 'him',
    [PRONOUN_TYPES.POSSESSIVE_ADJECTIVE]: 'his',
    [PRONOUN_TYPES.POSSESSIVE_PRONOUNS]: 'his',
    [PRONOUN_TYPES.REFLEXIVE]: 'himself',
  },
  NoGender: {
    [PRONOUN_TYPES.SUBJECT]: 'your pup',
    [PRONOUN_TYPES.OBJECT]: 'their',
    [PRONOUN_TYPES.POSSESSIVE_ADJECTIVE]: 'their',
    [PRONOUN_TYPES.POSSESSIVE_PRONOUNS]: 'theirs',
    [PRONOUN_TYPES.REFLEXIVE]: 'theirself',
  },
};
