'use client';
import React from 'react';
import { useRouter } from 'next/router';
import { Redirect } from './Redirect';

export const RedirectToLogin = () => {
  const router = useRouter();
  const { asPath } = router;
  const routerObj = {
    pathname: '/auth/login',
    query: { next: asPath },
  };
  return <Redirect to={routerObj} />;
};

RedirectToLogin.displayName = 'RedirectToLogin';
