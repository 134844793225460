import { useCallback } from 'react';
import { useSWRConfig } from 'swr';
import { useAuthenticatedSWR, useService, useSession } from '../api';
import { useLocalStorage } from '../hooks';
import { PET_URL, EMPTY_PET } from './constants';
import { PetService } from './service';
import { usePets } from './usePets';

/**
 * Valid petId. Empty string is treated as "new pet".
 *
 * @param {string} [petId]
 */
export const usePet = petId => {
  const service = useService(PetService);
  const { mutate: globalMutate, cache } = useSWRConfig();
  const { pets, refreshPets } = usePets();
  const { data, error, mutate } = useAuthenticatedSWR(
    petId ? `${PET_URL}${petId}/` : null,
  );
  const createPet = async pet => {
    if (!pet.birth_date) delete pet.birth_date;
    const response = await service.create(pet);
    globalMutate(`${PET_URL}${response.data.id}/`, response.data, false);
    globalMutate(`${PET_URL}`, { results: pets }, true);
    return response;
  };

  const updatePet = async pet => {
    const response = await service.update(data.id, pet);
    mutate(response.data, false);
    await refreshPets();
    const keys = cache.keys();
    for (const key of keys) {
      if (key.includes('product')) globalMutate(key);
    }
    return response;
  };

  const savePet = async pet => (data?.id ? updatePet(pet) : createPet(pet));

  const deletePet = async () => {
    return service.delete(data.id);
  };
  return {
    pet: data ? data : EMPTY_PET,
    error,
    isLoading: petId ? !data && !error : false,
    refreshPet: useCallback(pet => mutate(pet), [mutate]),
    savePet,
    deletePet,
    createPet,
  };
};

const LOCAL_PET_KEY = 'pet';

export const usePetWithLocalFallback = petId => {
  const { service: session } = useSession();
  const petFns = usePet(petId);
  const [localPetValues, setLocalPet] = useLocalStorage(
    LOCAL_PET_KEY,
    EMPTY_PET,
  );

  const savePet = async values => {
    if (session?.user?.id) {
      // If the session was just created for the first time, the breeds and allergies data
      // will be an object of { id, name } coming from local storage, which is needed to populate
      // the multi-selects with default values. However on subsequent savePet calls, these will
      // already be correctly passed as just strings of the id's so we don't do anything because
      // and array of strings is what the backend expects.
      if (values.breeds)
        values.breeds = values.breeds.map(breed =>
          typeof breed === 'object' ? breed.id : breed,
        );
      if (values.allergies)
        values.allergies = values.allergies.map(allergy =>
          typeof allergy === 'object' ? allergy.id : allergy,
        );
      return await petFns.savePet(values);
    }

    const pet = { ...localPetValues, ...values };
    setLocalPet(pet);
    return { status: 201, data: pet };
  };

  const deleteLocalPet = () => setLocalPet(EMPTY_PET);

  if (session?.user?.id) return { ...petFns, savePet };
  return { pet: localPetValues, savePet, isLocalPet: true, deleteLocalPet };
};
